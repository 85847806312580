@import '../../../vars';

.box-select{
    width: 163px;
    height: 31px;
    cursor: pointer;
    position: relative;
    // z-index:3;
    display: flex;
    letter-spacing: 2px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.select-green{
    background: none repeat scroll 0 0 #3CC139;
    &:hover{
        background: none repeat scroll 0 0 #60b85e;
    }
}
.select-red{
    background: none repeat scroll 0 0 #F25A5A;
    &:hover{
        background: none repeat scroll 0 0 #ee6969;
    }
}
.select-yellow{
    background: none repeat scroll 0 0 #F2D15A;
    &:hover{
        background: none repeat scroll 0 0 #ebd481;
    }
}
.select-gray{
    background: none repeat scroll 0 0 #AEAEAE;
    &:hover{
        background: none repeat scroll 0 0 #bdbcbc;
    }
}
.item-select-list{
    background: none repeat scroll 0 0 #fff;
    border: 1px solid #dedede;
    border-radius: 15px;
    box-shadow: 0px 6px 22px 3px rgba(0, 0, 0, 0.1);
    left: -1px;
    margin-top: 49px;
    padding-left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    & li{
        margin: 20px 13px 19px 13px;
        text-align: center;
      
        border-radius: 22px;
        cursor: pointer;
        list-style: outside none none; 
        padding: 7px 18px 8px 18px;
        & span{
            color: $--color-write;
            font-weight: 500;
        }
    }
}
.item-selected{
    color: white;
    font-weight: 500;
    border-radius: 22px;
    width: 135px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
}