 @import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
body {
  margin: 0;
   font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


