@import './vars';

.text-center{
    text-align: center;
}
.text-sm{
    font-size: 12px;
}
.text-md{
    font-size: 14px;
}
.text-base{
    font-size: 16px;
}
.text-lg{
    font-size: 18;
}
.text-xl{
    font-size: 20px;
}
.text-xxl{
    font-size: 22px;
}
.text-xxl2 {
    font-size: 24px;   
}
.font-medium{
    font-weight: 500;
}
.font-secundary{
    font-family: $--second-font;
}
.div-center{
    display: flex;
    align-items: center;
}
.pointer{
    cursor: pointer;
}