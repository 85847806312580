.SpinnerStyle {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-Right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-Bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-Left: 1.1em solid #34d399;
    transform: translateZ(0);
    animation: loading 1s linear infinite alternate;
    
      &:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
      }
  }

  @keyframes loading{
    0% {
    transform: rotate(0deg),
    }
    100% {
        transform: rotate(360deg),
    }
}