@import '../../vars';

.header{
    background-color: $--primary-color;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.btn-logout{
    margin-right: 124px;
    background: #045DFF;
    border: none;
    border-radius: 22px;
    width: 135px;
    height: 31px;
    color: $--color-write;
    letter-spacing: 2px;
    cursor: pointer;
    &:hover{
        background-color: #226cf7;
    }
}

// responsive hasta 540
@media (max-width: 540px) {
    .btn-logout{
        margin-right: 10px;
    }
}