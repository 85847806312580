@import '../../../vars';

.container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    // position: relative;
    // padding-bottom: 8em;
    // min-height: 100vh;
}
.body-access{
    padding: 12px 338px 0px 338px;
    // position: relative;
    // min-height: 500px;
}

.title-admin-access{
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.line-separator{
    text-align: center;
    position: relative;
    z-index: 1;
    // margin: 0px;
    // padding: 0px;
    &::before{
        border-top: 2px solid  #EBEAEC;;
        // opacity: 0.1;
        content: "";
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }
    & span{
        letter-spacing: -0.2px;
        color: #141414;
        mix-blend-mode: normal;
        opacity: 0.5;
        padding: 0 15px;
        background-color: $--color-write;
    }
}

.admin-access-loading{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
// responsive hasta 950
@media (max-width: 1024px) and (min-width: 541px){
    .body-access{
        padding: 0px 12px 0px 12px;
    }
}
// responsive hasta 540
@media (max-width: 540px) {
    .body-access{
        padding: 0px 12px 0px 12px;
    }
}